<template>
  <div class="app-content pt-3 p-md-3 p-lg-4">
    <div class="container-xl">
      <h1 class="app-page-title">User List</h1>
      <hr class="mb-4" />
      <div class="row g-4 settings-section">
        <div class="col-12 col-md-12">
          <div class="app-card app-card-settings shadow-sm p-4">
            <div class="app-card-body">
              <div v-if="isLoading" class="text-center">Please Waiting...</div>
               <table class="table table-sm table-bordered" v-if="!isLoading">
                 <thead class="text-center">
                   <tr>
                     <th>SL</th>
                     <th>Name</th>
                     <th>Phone</th>
                     <th>Email</th>
                   </tr>
                 </thead>
                 <tbody class="text-center">
                   <tr v-for="(user, ind) in users" :key="ind">
                     <td>{{ ind+1 }}</td>
                     <td>{{ user.name }}</td>
                     <td>{{ user.phone }}</td>
                     <td>{{ user.email }}</td>
                   </tr>
                 </tbody>
               </table>
            </div>
            <!--//app-card-body-->
          </div>
          <!--//app-card-->
        </div>
      </div>
      <!--//row-->
    </div>
    <!--//container-fluid-->
  </div>
  <!--//app-content-->
</template>
<script>
export default {
  name: "Users",
  data: () => ({
    isLoading: false,
    users: [],
  }),
  async created() {
    this.isLoading = true;
    await this.$store.dispatch('auth/getUsers');
    this.users = await this.$store.getters['auth/users'];
    this.isLoading = false;
  },
  methods: {
    
  }
};
</script>